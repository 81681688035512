import React from "react";
import "./MFA.css";
import logo from "../../img/logo.svg";
import isometric from "../../img/boerse-lynx-das-boersen-portal.svg";
import ReCAPTCHA from "react-google-recaptcha";

import queryString from "query-string";
import "bootstrap/dist/css/bootstrap.min.css";

import Counter from "../Counter/Counter.js";
import { FormattedMessage } from "react-intl";

import AuthService from "../../AuthService";
import ConfigService from "../../ConfigService";
import * as AnalyticsService  from "../../AnalyticsService.js";


const recaptchaRef = React.createRef();
const locale = ConfigService.getLocale();
const configData = ConfigService.getConfig();

export default class MFA extends React.Component {
    state = {
        user: "",
        code: "",
    };

    child = React.createRef();

    resetTimer = () => {
        if (this.child.current) {
            this.child.current.startTimer();
        }
        this.setState({ isDisabled: true });
    };

    onExpire = () => this.setState({ isDisabled: false });

    // On route load, it will trigger componentDidMount method and signin
    // into Cognito, using phone as username and hardcoded password.
    // This will trigger the MFA service to send a OTP SMS to the user.

    async componentDidMount() {
        this.resetTimer();
        // Read user from "somewhere"
        const values = queryString.parse(this.props.location.search);

        try {
            const token = await recaptchaRef.current.executeAsync();

            const username = values.username;

            const user = await AuthService.signIn({
                username: username,
                captchaToken: token,
            });

            this.user = user;

            const { challengeName } = this.user;

            // console.log(user);

            if (!challengeName) {
                const { history } = this.props;
                history.push("/", { err: "No current user" });
            }
        } catch (error) {
            console.log("error signing in:", error.code);

            // if captcha failed -> show message "start over. link"

            if (error.message === "User is not confirmed.") {
                let valuesUnparsed = this.props.location.search;
                let replacer = "&";
                valuesUnparsed = replacer.concat(valuesUnparsed.slice(1));
                this.state.username = values.username;
                try {
                    recaptchaRef.current.reset();
                    const token = await recaptchaRef.current.executeAsync();
                    await AuthService.resendSignUp({
                        username: this.state.username,
                        captchaToken: token,
                    });
                    AnalyticsService.push("mfa_code_resent");
                    console.log("code resent successfully");
                    const { history } = this.props;
                    history.push("/verify?" + valuesUnparsed);
                } catch (err) {
                    console.log("error resending code: ", err);
                    const { history } = this.props;
                    history.push("/verify?" + valuesUnparsed);
                }
            } else {
                AnalyticsService.pushError("unknown_error",error.code);
                this.setState({
                    errorMessage: "sms.error.unknown_error",
                    goodCode: false,
                });
            }
        }
    }

    // The resendConfirmationCode method will send the user back to Register route (/) including
    // the phone number and existing url param data. There, it will prefill the phone number and
    // come back to MFA route (/MFA) to trigger a new MFA session and a new SMS being sent.

    resendConfirmationCode = async (e) => {
        const values = queryString.parse(this.props.location.search);
        AnalyticsService.push('resendConfirmationCode_after_30s');
        const valuesUnparsed = this.props.location.search;
        console.log(valuesUnparsed);
        this.state.username = values.username;
        this.state.onlyPhone = values.phone;
        this.state.countryCode = values.countrycode;

        const { history } = this.props;
        history.push("/" + valuesUnparsed + "&resendMFA=true");
    };

    handleChange = (field, { target: { value } }) =>
        this.setState({
            [field]: value,
        });

    handleSubmit = async (e) => {
        e.preventDefault();
        const { code } = this.state;

        const values = queryString.parse(this.props.location.search);
        const valuesUnparsed = this.props.location.search;

        try {
            recaptchaRef.current.reset();
            const token = await recaptchaRef.current.executeAsync();
            // Read user from "somewhere"
            await AuthService.confirmSignIn({
                user: this.user,
                code: code,
                mfaType: "SMS_MFA",
                captchaToken: token,
            });

            // Trigger GTM Event
            const comment = "MFA Attempt (Phone verification already done)";
            AnalyticsService.push("valid_mfa_code","phone validation",comment);

            // Get DOF Url for redirect from the locale-config.json
            let dofUrl;
            dofUrl = configData[locale]["dof"];

            // Redirect to DOF

            dofUrl = dofUrl + valuesUnparsed;
            window.location.replace(dofUrl);
        } catch (err) {
            if (err.message === "Invalid code or auth state for the user.") {
                const errorMessage =  "sms.error.incorrect"
                AnalyticsService.pushError('incorrect_confirmation_code');
                this.setState({
                    errorMessage,
                    goodCode: false,
                });
            } else if (
                err.message ===
                "Invalid session for the user, session is expired."
            ) {
                AnalyticsService.pushError("timedout_code_expired");
                this.setState({
                    errorMessage: "sms.error.expired",
                    goodCode: false,
                });
            } else if(err.code === 'country_error'){
                AnalyticsService.pushError("country_blocked");
                this.setState({
                    errorMessage: "sms.error.unknown_error",
                    goodCode: false,
                });
            }else {
                console.error({unknown_error: err});
                AnalyticsService.pushError("unknown_error");
                this.setState({
                    errorMessage: "sms.error.unknown_error",
                    goodCode: false,
                });
            }
        }
    };

    onCaptchaError = (error) => {
        console.error("Captcha error:", error);
        AnalyticsService.pushError("captcha_error");
        this.setState({
            errorMessage: "error.captcha",
            goodCode: false,
        });
    };

    render() {
        const values = queryString.parse(this.props.location.search);
        const restartURL = configData[locale]["restartURL"];
        const captchaSiteKey = ConfigService.getCaptchaSiteKey();

        return (
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-sm-5 verticalCenter px-4">
                        <img className="mainLogo" src={logo} alt="Lynx Logo" />

                        <h1 className="app">
                            <FormattedMessage id="sms_code" />
                        </h1>
                        <p className="app">
                            <FormattedMessage
                                id="enter_code"
                                values={{
                                    username: values.username,
                                    strong: (word) => <strong>{word}</strong>,
                                }}
                            />
                        </p>
                        <form onSubmit={this.handleSubmit}>
                            <div>
                                <input
                                    type="text"
                                    className="smscode"
                                    autoFocus
                                    placeholder="sms-code"
                                    value={this.state.code}
                                    onChange={this.handleChange.bind(
                                        this,
                                        "code"
                                    )}
                                />
                                {this.state.goodCode === false ? (
                                    <p className="wrongCode">
                                        <FormattedMessage
                                            id={this.state.errorMessage}
                                            values={{
                                                a: (...chunks) => (
                                                    <a
                                                        href={restartURL}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {chunks}
                                                    </a>
                                                ),
                                            }}
                                        />
                                    </p>
                                ) : null}
                                <button
                                    className="btn btn-primary register empty"
                                    type="button"
                                    disabled={this.state.isDisabled}
                                    onClick={this.resendConfirmationCode}
                                >
                                    <FormattedMessage id="resend" />
                                    {"\u00A0"}
                                    <Counter
                                        ref={this.child}
                                        onExpire={this.onExpire}
                                    />
                                </button>
                                <button
                                    className="btn btn-primary register"
                                    type="submit"
                                    disabled={!this.state.code}
                                >
                                    <FormattedMessage id="confirm_identity" />
                                </button>
                                <ReCAPTCHA
                                    className="captcha"
                                    ref={recaptchaRef}
                                    sitekey={captchaSiteKey}
                                    onErrored={this.onCaptchaError}
                                    size="invisible"
                                />
                            </div>
                        </form>
                    </div>
                    <div className="col-7 d-none d-sm-flex  pt-5 verticalCenter">
                        <img src={isometric} alt="Lynx Broker" />
                    </div>
                </div>
            </div>
        );
    }
}
