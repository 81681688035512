import React from "react";
/*import PropTypes from 'prop-types';*/
import "./Verify.css";

/*import Amplify from "@aws-amplify/core";*/
import queryString from "query-string";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../img/logo.svg";
import isometric from "../../img/boerse-lynx-das-boersen-portal.svg";
import ReCAPTCHA from "react-google-recaptcha";

import Counter from "../Counter/Counter.js";
import { FormattedMessage } from "react-intl";

import AuthService from "../../AuthService";
import ConfigService from "../../ConfigService";
import * as AnalyticsService  from "../../AnalyticsService.js";

const recaptchaRef = React.createRef();
const locale = ConfigService.getLocale();
const configData = ConfigService.getConfig();

export default class Verify extends React.Component {
    state = {
        username: this.username,
        email: this.email,
        code: "",
    };

    child = React.createRef();

    resetTimer = () => {
        if (this.child.current) {
            this.child.current.startTimer();
        }
        this.setState({ isDisabled: true });
    };

    async componentDidMount() {
        this.resetTimer();
    }

    onExpire = () => this.setState({ isDisabled: false });

    handleChange = (field, { target: { value } }) =>
        this.setState({
            [field]: value,
        });

    resendConfirmationCode = async (e) => {
        recaptchaRef.current.reset();
        const token = await recaptchaRef.current.executeAsync();
        this.resetTimer();
        const values = queryString.parse(this.props.location.search);
        console.log(values.username);

        this.state.username = values.username;

        try {
            await AuthService.resendSignUp({
                username: this.state.username,
                captchaToken: token
            });
            AnalyticsService.push('resent_confirmation_code');
            console.log("code resent successfully");
        } catch (err) {
            console.log("error resending code: ", err);
            AnalyticsService.pushError('ERROR_resend_confirmation_code',err);
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        // Auth in Cognito using Phone number as username and entered SMS code

        const values = queryString.parse(this.props.location.search);
        const valuesUnparsed = this.props.location.search;

        this.state.username = values.username;

        try {
            recaptchaRef.current.reset();
            const token = await recaptchaRef.current.executeAsync();

            const { user } = await AuthService.confirmSignUp({
                username: this.state.username,
                code: this.state.code,
                captchaToken: token
            });

            this.state.username = this.state.username.replace(/\+/g, "%2b");

            // Get DOF Url from locale-config.json

            let dofUrl;
            dofUrl = configData[locale]["dof"];

            // Redirect to DOF
            dofUrl = dofUrl + valuesUnparsed;
            AnalyticsService.push('correct_confirmation_code');
            window.location.replace(dofUrl);
        } catch (error) {
            console.log("error signing up:", error.code);
            if (error.code === "CodeMismatchException") {
                AnalyticsService.pushError('incorrect_confirmation_code');
                this.setState({
                    errorMessage: "sms.error.incorrect",
                    goodCode: false,
                });
            } else if (
                err.message ===
                "Invalid session for the user, session is expired."
            ) {
                AnalyticsService.pushError("timedout_code_expired");
                this.setState({
                    errorMessage: "sms.error.expired",
                    goodCode: false,
                });
            } else if (err.code === 'country_error') {
                AnalyticsService.pushError("country_blocked");
                this.setState({
                    errorMessage: "sms.error.unknown_error",
                    goodCode: false,
                });
            } else {
                console.error({ unknown_error: err });
                AnalyticsService.pushError("unknown_error", err);
                this.setState({
                    errorMessage: "sms.error.unknown_error",
                    goodCode: false,
                });
            }
        }
    };

    onCaptchaError = (error) => {
        console.error("Captcha error:", error);
        this.setState({
            errorMessage: "error.captcha",
            goodCode: false,
        });
    };

    render() {
        const values = queryString.parse(this.props.location.search);
        const restartURL = configData[locale]["restartURL"];
        const captchaSiteKey = ConfigService.getCaptchaSiteKey();

        return (
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-sm-5 verticalCenter px-4">
                        <img className="mainLogo" src={logo} alt="Lynx Logo" />

                        <h1 className="app">
                            <FormattedMessage id="sms_code" />
                        </h1>
                        <p className="app">
                            <FormattedMessage
                                id="enter_code"
                                values={{
                                    username: values.username,
                                    strong: (word) => <strong>{word}</strong>,
                                }}
                            />
                        </p>

                        <form onSubmit={this.handleSubmit}>
                            <div>
                                <input
                                    type="text"
                                    className="smscode"
                                    autoFocus
                                    value={this.state.code}
                                    placeholder="sms-code"
                                    onChange={this.handleChange.bind(
                                        this,
                                        "code"
                                    )}
                                />
                                {this.state.goodCode === false ? (
                                    <p className="wrongCode">
                                        <FormattedMessage id={this.state.errorMessage} values={{
                                            a: (...chunks) => <a href={restartURL} target="_blank" rel="noopener noreferrer">{chunks}</a>
                                        }} />
                                    </p>
                                ) : null}

                                <button
                                    className="btn btn-primary register empty"
                                    type="button"
                                    disabled={this.state.isDisabled}
                                    onClick={this.resendConfirmationCode}
                                >
                                    <FormattedMessage id="resend" />
                                    {"\u00A0"}
                                    <Counter
                                        ref={this.child}
                                        onExpire={this.onExpire}
                                    />
                                </button>

                                <button
                                    className="btn btn-primary register"
                                    type="submit"
                                >
                                    <FormattedMessage id="verify" />
                                </button>
                                <ReCAPTCHA
                                    className="captcha"
                                    ref={recaptchaRef}
                                    sitekey={captchaSiteKey}
                                    onErrored={this.onCaptchaError}
                                    size="invisible"
                                />
                            </div>
                        </form>
                    </div>
                    <div className="col-7 d-none d-sm-flex pt-5 verticalCenter">
                        <img src={isometric} alt="Lynx Broker" />
                    </div>
                </div>
            </div>
        );
    }
}
