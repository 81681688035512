import React from "react";

export default class Counter extends React.Component {
  state = { seconds: 30 };
  timer = 0;

  secondsToTime(secs) {
    const hours = Math.floor(secs / (60 * 60));

    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);

    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);

    return {
      h: hours,
      m: minutes,
      s: seconds
    };
  }

  componentDidUpdate() {
    if (!this.state.seconds) {
      clearInterval(this.timer);
      this.timer = 0;
      this.props.onExpire();
      this.setState({ seconds: 30 });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimer = () => {
    if (!this.timer) {
      if (!this.state.seconds) {
        this.setState({ seconds: 30 });
      }
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    this.setState((prevState) => ({ seconds: prevState.seconds - 1 }));
  };

  render() {
    const { s } = this.secondsToTime(this.state.seconds);
    return (
      <span className={this.state.seconds !== 30 ? "timerActive" : "timerHidden"}>
        ({s})
      </span>
    );
  }
}