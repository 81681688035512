

import ConfigService from "./ConfigService";
import TagManager from "react-gtm-module";

let initDone = false;
const init = () => {
    if (initDone) {
        console.error("AnalyticsService init called again, skipping");

    } else {
        const locale = ConfigService.getLocale();
        const gtm = ConfigService.getConfig()[locale]["gtm"];
        console.log({ gtm });
        const tagManagerArgs = {
            gtmId: gtm,
        };
        TagManager.initialize(tagManagerArgs);
        initDone = true;
    }
}

init();

const push = (eventInfo) => _push('mfa_info', false,eventInfo,null );
const pushError = (eventInfo, errorData) => _push('mfa_error', true, eventInfo, errorData)

const _push = (eventName, isError, detail, errorData) => {

    const lynxFunnelStepMFA = 3;
    const tagManagerArgs = {
        dataLayer: {
            event: eventName,
            event_data: {
                isError,
                detail: detail,
                errorData,
                step_number: lynxFunnelStepMFA,
                isProduction: !ConfigService.isAcc()
            },
        },
    };
    console.log({ gtag_event: tagManagerArgs.dataLayer });
    TagManager.dataLayer(tagManagerArgs);
};

export { push, pushError }