import React from "react";
import ReactDOM from "react-dom";
/*import QRCode from 'qrcode.react';
 */
import { BrowserRouter, Redirect, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import Register from "./components/Register/Register.js";
import Verify from "./components/Verify/Verify.js";
import MFA from "./components/MFA/MFA.js";

import ConfigService from "./ConfigService";

import "./styles.css";

import { IntlProvider } from "react-intl";
import German from "./lang/de.json";
import Dutch from "./lang/nl.json";
import Finnish from "./lang/fi.json";
import Czeck from "./lang/cz.json";
import Slovak from "./lang/sk.json";
import Polish from "./lang/pl.json";
import French from "./lang/fr.json";

const locale = ConfigService.getLocale();
/**
 * React feature: PUBLIC_URL is set in package.json as 'homepage'.
 */
const rootPath = process.env.PUBLIC_URL;

// Clear the history to enforce starting at the root
window.history.replaceState({}, document.title);

const renderApp = async () => {
    // Map strings from locale-config.json to language objects imported above

    let langs = {
        German: German,
        Dutch: Dutch,
        Finnish: Finnish,
        French: French,
        Polish: Polish,
        Slovak: Slovak,
        Czeck: Czeck,
    };
    const configData = ConfigService.getConfig();
    const lang = langs[configData[locale]["language"]];

    console.log({ locale, lang });
    const App = () => (
        <IntlProvider locale={locale} messages={lang}>
            <BrowserRouter>
                <Route path="/">
                    <Redirect to={rootPath}> </Redirect> 
                </Route>
            </BrowserRouter>
            <BrowserRouter basename={rootPath}>
                <div className="h-100">
                    <Route path="/" component={Register} exact={true} />
                    <Route path="/mfa" component={MFA} />
                    <Route path="/verify" component={Verify} />
                </div>
            </BrowserRouter>
        </IntlProvider>
    );

    const rootElement = document.getElementById("root");
    ReactDOM.render(<App />, rootElement);
};
renderApp();
