import React from "react";
import AuthService from "../../AuthService";
import ConfigService from "../../ConfigService";

import "./Register.css";

import ReCAPTCHA from "react-google-recaptcha";
import queryString from "query-string";

import "bootstrap/dist/css/bootstrap.min.css";

import logo from "../../img/logo.svg";
import isometric from "../../img/boerse-lynx-das-boersen-portal.svg";

import PhoneInput from "react-phone-input-2";
import de from 'react-phone-input-2/lang/de.json'
import fr from 'react-phone-input-2/lang/fr.json'
import pl from 'react-phone-input-2/lang/pl.json'
import nl from '../../phone_locales/nl.json'
import sk from '../../phone_locales/sk.json'
import fi from '../../phone_locales/fi.json'
import cz from '../../phone_locales/cz.json'
import * as AnalyticsService  from "../../AnalyticsService.js";

const phone_locales = {
  de: de,
  pl: pl,
  fr: fr,
  nl: nl,
  sk: sk,
  fi: fi,
  cz: cz
};

import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/high-res.css";

import isMobilePhone from 'validator/lib/isMobilePhone';
import { FormattedMessage } from 'react-intl';

const recaptchaRef = React.createRef();
const locale = ConfigService.getLocale();
const configData = ConfigService.getConfig();

export default class Register extends React.Component {
  state = {
    username: "",
    password: "123456789La?",
    authCode: "",
    email: "",
    country: "",
    countryCode: "",
    onlyPhone: "",
    goodCode: true,
    errorMessage: ""
  };

  handleChange = (field, { target: { value } }) =>
    this.setState({
      [field]: value,
    });

  handleSubmit = async (e) => {
    const values = queryString.parse(this.props.location.search);
    let valuesUnparsed = this.props.location.search;

    let replacer = '&';
    valuesUnparsed = replacer.concat(valuesUnparsed.slice(1));
    this.state.email = values.email;
    try {

      recaptchaRef.current.reset();
      const token = await recaptchaRef.current.executeAsync();
      const { user } = await AuthService.signUp({
        username: this.state.username,
        attributes: {
          email: values.email,
        },
        captchaToken: token
      })

      this.state.username = this.state.username.replace(/\+/g, "%2b");

      const { history } = this.props;
      /*console.log(history);*/
      history.push(
        "/verify?username=" + this.state.username + "&phone=" + this.state.onlyPhone +
        "&countrycode=" + this.state.countryCode + valuesUnparsed
      );
    } catch (error) {
      console.log("error signing up:", error.code);

      if (error.code === "UsernameExistsException") {
        this.state.username = this.state.username.replace(/\+/g, "%2b");
        const { history } = this.props;
        history.push(
          "/mfa?username=" + this.state.username + "&phone=" + this.state.onlyPhone +
          "&countrycode=" + this.state.countryCode + valuesUnparsed
        );
      } else if (error.code === "timeout-or-duplicate") {
        AnalyticsService.pushError(`captcha-expired`);
        console.log("Captcha expired, need to rerun the challenge");
      }
    }
  };

  clearValidation = async (e) => {
    this.setState({
      errorMessage: '',
      goodphone: true
    });

  };

  validatePhoneNum = async (e) => {
    if (this.state.username.indexOf('+') === -1) {
      this.state.username = "+" + this.state.username;
    }
    let validPhone = isMobilePhone(this.state.username, "any");
    if (validPhone === false) {
      this.setState({
        errorMessage: 'incorrect_number',
        goodphone: false
      });
    } else {
      this.handleSubmit();
    }
  };

  separateCountryfromPhone = async (country, username) => {
    await this.setState({ country });
    await this.setState({ username });

    // Split username into phone and country code 

    let fullPhone = this.state.username;
    let countryCode = this.state.country.dialCode;
    let onlyPhone = "";

    onlyPhone = fullPhone.replace(countryCode, "");
    countryCode = "+" + countryCode;

    // Check for first number being 0, and remove it

    if (onlyPhone.startsWith("0")) {

      onlyPhone = onlyPhone.slice(1);
      fullPhone = countryCode + onlyPhone;
      fullPhone = fullPhone.replace("+", "");

      this.setState({ onlyPhone: onlyPhone, countryCode: countryCode, username: fullPhone });
    } else {
      this.setState({ onlyPhone: onlyPhone, countryCode: countryCode });
    }
  }


  // On route/page load, the componentDidMount method will check if the URL params
  // include a "resend" param, which tells it that the route comes redirected from the MFA component (/MFA)
  // If this is the case, this means that the user is performing a "Resend SMS" because the session expired
  // The method will grab the phone number from the URL params, and resubmit the form using the handeSubmitResend method
  // The basic step-by-step logic is:
  // User is in MFA route, gets SMS late, or never. Clicks on Resend SMS
  // The MFA route redirects the user to root path (Register route) including all URL params + "resendMFA" param
  // The Register route catches the "resend" param, and uses the phone to submit the from
  // The user gets redirected back to the MFA route, which triggers a new session and the user gets a new SMS 

  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    /*console.log(values);*/
    if (values.resendMFA) {
      values.countrycode = values.countrycode.replace(" ", "");
      this.state.username = "+" + values.countrycode + values.phone;
      /*console.log(this.state.username);*/
      this.handleSubmitResend();
    }

    this.setState({ email: values.email });
  }

  handleSubmitResend = async (e) => {

    const values = queryString.parse(this.props.location.search);
    let valuesUnparsed = this.props.location.search;

    const { history } = this.props;

    history.push(
      "/mfa" + valuesUnparsed
    );
  };

  onCaptchaError = (error) => {
    console.error("Captcha error:", error);
    AnalyticsService.pushError('captcha_error',error);
    this.setState({
      errorMessage: "error.captcha",
      goodCode: false,
    });
  };

  render() {

    // Get URL domain to determine which website and language we come from

    let dataProtection;
    let phoneCode;
    let search;
    let country_list;

    // Get data protection link and phone int. code from locale-config.json
    dataProtection = configData[locale]["privacy"];
    phoneCode = configData[locale]["phoneCode"];
    search = configData[locale]["search"];
    country_list = configData[locale]["country_list"];
    const restartURL = configData[locale]["restartURL"];
    const captchaSiteKey = ConfigService.getCaptchaSiteKey();
    let active_phone_locales = phone_locales[country_list];

    const preferredCountries =  ['at', 'be', 'ch', 'cz', 'de', 'fi', 'fr', 'nl', 'pl', 'sk'];
    const eeu =  ['at', 'be', 'bg', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gr', 'hr', 'hu', 'ie', 'is', 'it', 'li', 'lt', 'lu', 'lv', 'mt', 'nl', 'no', 'pl', 'pt', 'ro', 'se', 'si', 'sk', 'tr'];
    return (
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-sm-5 verticalCenter px-4">
            <img className="mainLogo" src={logo} alt="Lynx Logo" />
            <h1 className="app">
              <FormattedMessage
                id="mobile"
                defaultMessage="Your mobile phone"
              />
            </h1>
            <p className="app">
              <FormattedMessage
                id="enter_your_mobile"
                values={{
                  linebreak: <br />
                }}
              />
            </p>

            <form name="register" onSubmit={this.handleSubmit}>
              <div>
                <PhoneInput
                  searchPlaceholder={search}
                   onlyCountries={eeu}
                  preferredCountries={preferredCountries}
                  localization={active_phone_locales}
                   /** 
                    * enableLongNumbers
                    * to allow weird  numbers. This disabled the restriction on number length.
                    * */
                  enableLongNumbers={18}
                  country={phoneCode}
                  value={this.state.username}
                  onChange={(username, country) => { this.separateCountryfromPhone(country, username) }}
                  countryCodeEditable={false}
                  enableSearch={true}
                  disableSearchIcon={true}
                  inputProps={{
                    name: "phone",
                    required: true,

                  }}
                  isValid={this.state.goodphone}
                  onFocus={() => this.clearValidation()} //work
                />
                {this.state.goodphone === false ? <p className="wrongPhone">
                  <FormattedMessage
                    id={this.state.errorMessage}
                  />
                </p> : null}
                {this.state.goodCode === false ? 
                  <p className="wrongPhone">
                    <FormattedMessage id={this.state.errorMessage} values={{
                      a: (...chunks) => <a href={restartURL} target="_blank" rel="noopener noreferrer">{chunks}</a>
                    }} />
                  </p> : null}
                <p className="notice">
                  <FormattedMessage
                    id="data_protection"
                    values={{
                      a: (...chunks) => <a href={dataProtection} target="_blank" rel="noopener noreferrer">{chunks}</a>
                    }}
                  />
                </p>
                <button className="btn btn-primary register" type="button" onClick={this.validatePhoneNum} disabled={!this.state.email}>
                  <FormattedMessage
                    id="get_confirmation"
                  />
                </button>
                <ReCAPTCHA
                  className="captcha"
                  ref={recaptchaRef}
                  sitekey={captchaSiteKey}
                  onErrored={this.onCaptchaError}
                  size="invisible"
                />
              </div>
            </form>
          </div>
          <div className="col-7 d-none d-sm-flex pt-5 verticalCenter">
            <img src={isometric} alt="Lynx Broker" />
          </div>
        </div>
      </div>
    );
  }
}
