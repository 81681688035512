import axios from "axios";
const rootPath = process.env.PUBLIC_URL;

const AuthService = {
	signUp: function (data) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(`${rootPath}/signup/`, data);
				resolve(response);
			} catch (error) {
				reject(handleError(error));
			}
		})
	},
	signIn: function (data) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(`${rootPath}/signin/`, data);
				resolve(response.data);
			} catch (error) {
				reject(handleError(error));
			}
		})
	},
	resendSignUp: function (data) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(`${rootPath}/resendsignup/`, data);
				resolve(response);
			} catch (error) {
				reject(handleError(error));
			}
		})
	},
	confirmSignIn: function (data) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(`${rootPath}/confirmsignin/`, data);
				resolve(response.data);
			} catch (error) {
				reject(handleError(error));
			}
		})
	},
	confirmSignUp: function (data) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(`${rootPath}/confirmsignup/`, data);
				resolve(response);
			} catch (error) {
				reject(handleError(error));
			}
		})
	},
}

function handleError(error) {
	if (error.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		return error.response.data;
		// console.log(error.response.status);
		// console.log(error.response.headers);
	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		return error.request;
	} else {
		// Something happened in setting up the request that triggered an Error
		return error.message;
	}
}

export default AuthService;