import configData from "./locale-config.json";
import configDataACC from "./locale-config-acc.json";
const localeFromUrl = window.location.host.split(".");
const DEFAULT_LOCALE = "de";
const isLocalEnvironment = localeFromUrl[0].indexOf("localhost") !== -1;
const isAcc = localeFromUrl[0].indexOf("staging") !== -1;

const ConfigService = {
	getConfig: function () {
		return isLocalEnvironment || isAcc ? configDataACC : configData;
	},
	getLocale: function () {
		let locale = DEFAULT_LOCALE;
		if (!isLocalEnvironment) {
			locale = localeFromUrl[localeFromUrl.length - 1];
		} else {
			console.log(
				`defaulting to default locale because its local env ( ${DEFAULT_LOCALE})`
			);
		}

		if (locale == "be") {
			if (
				window.location.host == "www.lynx.be" ||
				window.location.host == "staging.lynx.be" ||
				window.location.host == "account.lynx.be"
			) {
				locale = "nl-be";
			} else if (window.location.host == "fr.lynx.be" || window.location.host ==  "account-fr") {
				locale = "fr-be";
			}
		}
		return locale;
	},
	getCaptchaSiteKey: function (config) {
		config = config || this.getConfig();
		return config["captcha-site-key"];
	},
	isAcc:() => isAcc
};

export default ConfigService;
